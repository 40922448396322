 @import 'var';

 // Datepicker overrides
 .react-datepicker-popper {
     z-index: 10;

     .react-datepicker {
         font-family: inherit;
         font-size: 0.8rem;
         color: $text-color;
         border: none;
         border-radius: 0;
         transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
         box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
         transform-origin: center bottom;
         padding: 1rem;
         background-color: #fff;
     }

     .react-datepicker__header {
         background-color: #fff;
         border-bottom: none;
         border-top-left-radius: 0;
         padding: 8px 0;
     }

     .react-datepicker__navigation {
         top: 1.4rem;
     }

     .react-datepicker__year-read-view--down-arrow,
     .react-datepicker__month-read-view--down-arrow,
     .react-datepicker__month-year-read-view--down-arrow,
     .react-datepicker__navigation-icon::before {
         border-width: 2px 2px 0 0;
     }

     .react-datepicker__current-month,
     .react-datepicker-time__header,
     .react-datepicker-year-header {
         font-weight: 500;
         margin-bottom: 0.5rem;
     }


     .react-datepicker__day:hover,
     .react-datepicker__month-text:hover,
     .react-datepicker__quarter-text:hover,
     .react-datepicker__year-text:hover {
         border-radius: 0;
         background-color: rgba(0, 0, 0, 0.04);
     }

     .react-datepicker__day--selected,
     .react-datepicker__day--in-selecting-range,
     .react-datepicker__day--in-range,
     .react-datepicker__month-text--selected,
     .react-datepicker__month-text--in-selecting-range,
     .react-datepicker__month-text--in-range,
     .react-datepicker__quarter-text--selected,
     .react-datepicker__quarter-text--in-selecting-range,
     .react-datepicker__quarter-text--in-range,
     .react-datepicker__year-text--selected,
     .react-datepicker__year-text--in-selecting-range,
     .react-datepicker__year-text--in-range {
         border-radius: 15%;
         background-color: $primary;
         color: #fff;

         &:hover {
             color: #fff;
             border-radius: 15%;
             background-color: $primary-dark;
         }
     }

     .react-datepicker__day--in-selecting-range,
     .react-datepicker__month-text--in-selecting-range,
     .react-datepicker__quarter-text--in-selecting-range,
     .react-datepicker__year-text--in-selecting-range {
         &:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
             background-color: rgba($primary, 0.5);
         }
     }

     .react-datepicker__close-icon {
         right: .1875rem;
     }
 }